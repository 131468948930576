/* Reset default browser styles */
body,
p,
h1,
h2,
h3,
ul,
ol,
li {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

p {
    text-justify: auto;
}

/* Fonts */

@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica.ttf') format('ttf');
}

/* latin */
@font-face {
    font-family: 'Archivo';
    src: url('./fonts/Archivo.ttf') format('ttf');
    src: url('https://fonts.gstatic.com/s/archivo/v19/k3kPo8UDI-1M0wlSV9XAw6lQkqWY8Q82sLydOxKsv4Rn.woff2') format('woff2');
}

@font-face {
    font-family: 'IM Fell DW Pica';
    src: url('./fonts/Pica.ttf') format('truetype'), /* Corrected format specifier */
         url('./fonts/Pica.woff') format('woff'),
         url('https://fonts.gstatic.com/s/imfelldwpica/v16/2sDGZGRQotv9nbn2qSl0TxXVYNwNYwnVnCwsMA.woff2') format('woff2');
}

@font-face {
    font-family: 'Wavefont';
    src: url('./fonts/Wavefont.ttf') format('truetype'),
         url('./fonts/Wavefont.woff') format('woff'),
         url('https://fonts.gstatic.com/s/wavefont/v10/L0xFDF00m0cP6hefyOCpRezQNuizSrqDyx8FHbFu21B3L4m0SEzuQYwq-f_JJ8I1WI3V07DCXKtOXeA4.woff2') format('woff2');
}

@font-face {
    font-family: 'Zilla Slab';
    src: url('./fonts/ZillaSlab.ttf') format('truetype'),
         url('./fonts/ZillaSlab.woff') format('woff'),
         url('https://fonts.gstatic.com/s/zillaslab/v11/dFa6ZfeM_74wlPZtksIFajo6_V6LVlA.woff2') format('woff2');
}

:root {
    --header-height: 60px;
}

:root {
    --footer-height: 60px;
}

@media screen and (max-height: 480px) {
    :root {
        --header-height: 30px !important;
    }
}

.flexible-container {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    height: 100vh;
}

body {
    font-family: "Archivo", "Helvetica", sans-serif !important;
    overflow: hidden;
}

.header-object {
    height: var(--header-height);
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #ffffef;
    outline-width: 1px !important;
    outline-color: black;
    flex-direction: row;
    position: fixed;
    -webkit-position: fixed; /* iOS Safari */
    z-index: 720;
}

/* Updated default button styles */
.MuiButton-toolbar {
    height: 48px !important;
    position: fixed !important;
    outline-width: 0px !important;
    outline-color: black !important;
}

.MuiToolbar-root {
    min-height: 0px !important;
}

.sidebar-button {
    border-radius: 0px 0px 0px 0px !important; 
    padding-inline: 0px !important;
    text-align: center; 
    position: absolute;
    padding: 0px;
    width: 80px;
    height: 100%;
    margin-left: 10px !important;
}

.sidebar-button:hover,
.sidebar-button:hover h3 {
    color: #ffffef !important;
}

.sidebar-toggle {
    border-radius: 0px 0px 0px 0px !important; 
    text-align: center; 
    position: absolute;
    width: 80px;
    height: 100%;
    margin-left: 10px !important;
}

.collapsed-avatar {
    width: 38px !important;
    height: 38px !important;
    margin-bottom: 12px !important;
    transition: all 0.8s ease !important; /* Adjust the duration and timing function as needed */
  }
  
.expanded-avatar {
    width: 120px !important;
    height: 120px !important;
    margin: 8px !important;
    transition: all 0.8s ease !important; /* Adjust the duration and timing function as needed */
}

/* Updated default button styles */
.MuiButton-root {
    background-color: #ffffef;
    color: black;
    border: 0px solid transparent !important; /* Add a border to distinguish the button */
    display: block; /* Display as block-level elements to take full width */
    text-align: center; /* Center-align text within the button */
    margin: 0px auto !important; /* Center horizontally with a little margin between buttons */
    width: 80%; /* Set a fixed width of 100% */
    font-weight: bold; /* Set the font weight to bold */
    padding: 0px 0px !important;
    min-width: 0px !important;
}

.green-button {
    background-color: #ffffef;
    color: black;
    box-shadow: 0.5px 0.5px 0.5px 1px rgba(0, 0, 0, 0.1); 
    display: block !important; /* Display as block-level elements to take full width */
    text-align: center; /* Center-align text within the button */
    margin: 5px auto !important; /* Center horizontally with a little margin between buttons */
    width: 80%; /* Set a fixed width of 100% */
    font-weight: bold !important; /* Set the font weight to bold */
    transition: opacity 1s ease !important; /* Add a transition for the opacity property */
}
  
.green-button:hover {
    background-color: green !important;
    color: #ffffef !important;
}
  
.blue-button {
    background-color: #ffffef;
    color: black;
    box-shadow: 0.5px 0.5px 0.5px 1px rgba(0, 0, 0, 0.1); 
    display: block !important; /* Display as block-level elements to take full width */
    text-align: center; /* Center-align text within the button */
    margin: 5px auto !important; /* Center horizontally with a little margin between buttons */
    width: 80%; /* Set a fixed width of 100% */
    font-weight: bold !important; /* Set the font weight to bold */
    transition: opacity 1s ease !important; /* Add a transition for the opacity property */
}
  
.blue-button:hover {
    background-color: rgb(4, 4, 146) !important;
    color: #ffffef !important;
}
  
.yellow-button {
    background-color: #ffffef;
    color: black;
    box-shadow: 0.5px 0.5px 0.5px 1px rgba(0, 0, 0, 0.1); 
    display: block !important; /* Display as block-level elements to take full width */
    text-align: center; /* Center-align text within the button */
    margin: 5px auto !important; /* Center horizontally with a little margin between buttons */
    width: 80%; /* Set a fixed width of 100% */
    font-weight: bold !important; /* Set the font weight to bold */
    transition: opacity 1s ease !important; /* Add a transition for the opacity property */
}
  
.yellow-button:hover {
    background-color: #c78203 !important;
    color: #ffffef !important;
}
  
.red-button {
    background-color: #ffffef;
    color: black;
    box-shadow: 0.5px 0.5px 0.5px 1px rgba(0, 0, 0, 0.1); 
    display: block !important; /* Display as block-level elements to take full width */
    text-align: center; /* Center-align text within the button */
    margin: 5px auto !important; /* Center horizontally with a little margin between buttons */
    width: 80%; /* Set a fixed width of 100% */
    font-weight: bold !important; /* Set the font weight to bold */
    transition: opacity 1s ease !important; /* Add a transition for the opacity property */
}
  
.red-button:hover {
    background-color: red !important;
    color: #ffffef !important;
}

/* Default styles for the icons */
.icon-wrapper {
    padding: 3px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.icon-wrapper p,
.icon-wrapper svg {
    width: 18px; /* Default width */
    height: 18px; /* Default height */
}
  
/* Responsive styles for larger screens */
@media screen and (min-width: 480px) {
    .icon-wrapper p,
    .icon-wrapper svg {
      width: 24px;
      height: 24px;
    }
}

.sideways-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
}

.mobile-container {
    display: flex;
    width: 100%;
    height: 100%;
    /* padding-bottom: 50% !important; */
}

.main-object {
    margin-top: var(--header-height);
    display: flex;
    overflow: hidden;
    height: calc(100% - var(--header-height));
}

.background-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    user-select: none;
}

.background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: opacity 500ms ease-in-out;
}

.sidebar-wrapper {
    display: flex;
    position: relative;
    transition: width 1.3s ease;
    background-color: transparent;
    z-index: 100;
}

.navigation-sidebar {
    border-radius: 15px;
    margin: 15px; 
    height: calc(100% - var(--header-height) + 15px); 
    width: 100%;
    box-shadow: 1px 1px 2px 1.5px rgba(0, 0, 0, 0.3); 
    background-color: #ffffef;
    display: flex; 
    flex-direction: column; 
    align-items: center;
    position: relative; /* Make sure it's positioned relative to allow z-index to work */
    z-index: 100;
}

.button-text {
    font-family: "Helvetica", sans-serif;
}

.foreground-container {
    flex: 1;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    min-height: 100px;
    height: 100%;
    position: relative; /* Added relative positioning */
    transition: opacity 1000ms ease 500ms;
    user-select: none;
}

.foreground-center {
    max-height: 50vh;
    flex-wrap: wrap !important;
    justify-content: center !important;
    align-content: center !important;
    align-items: center !important;
    text-align: center;
    width: 100% !important; 
}

.foreground-picture {
    height: 100%;
    min-height: 450px;
    object-fit: cover;
    position: absolute;
    overflow: hidden; /* Clip the image at the bottom */
    left: -30px;
    right: 0;
    bottom: auto;
    z-index: 0;
}

.foreground-mobile {
    min-height: 100px !important;
    left: 0px !important;
    align-self: center !important;
    position: relative !important;
}

.content-wrapper {
    flex: 1;
}

.content-scroller {
    background-color: transparent;
    flex: 2;
    flex-direction: column;
    position: relative; /* Add relative positioning */
    align-items: center;
    min-width: 33%;
    overflow-y: auto;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: calc(44vh - var(--header-height));
    z-index: 600; /* Set a higher z-index value than the .foreground-picture */
}

.content-full {
    box-sizing: border-box;
    flex-direction: row !important;
    height: 100% !important;
    width: 100vw !important;
    padding-bottom: 44vh !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    min-height: 100vh;
}

.content-intro {
    border-radius: 15px;
    padding-inline-start: 14px;
    padding-inline-end: 12px;
    padding-top: 0px;
    padding-bottom: 15px;
    margin: 15px;
    min-width: 50%; /* Adjust as needed */
    box-shadow: 1px 1px 2px 1.5px rgba(0, 0, 0, 0.3);
    background-color: #ffffef;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Allow items to wrap to the next row */
    position: relative;
    z-index: 100;
}

.content-intro p {
    text-align: justify;
    justify-content: center;
}

.content-media {
    border-radius: 15px;
    margin: 15px;
    min-width: 50%; /* Adjust as needed */
    box-shadow: 1px 1px 2px 1.5px rgba(0, 0, 0, 0.3);
    background-color: #ffffef;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 100;
}

.media-container {
    border-radius: 15px;
    padding-inline-start: 14px;
    padding-inline-end: 12px;
    padding-top: 5px;
    padding-bottom: 15px;
    min-width: 50%; /* Adjust as needed */
    background-color: #ffffef;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 100;
}

.media-title {
    padding-top: 12px !important;
    padding-bottom: 15px;
}

.media-text {
    text-align: justify;
    padding-top: 0px !important;
    padding-bottom: 20px;
}

.content-experience {
    display: flex;
    border-radius: 15px;
    padding-inline-start: 20px;
    padding-inline-end: 12px;
    padding-top: 10px;
    padding-bottom: 16px;
    margin: 15px;
    min-width: 33%;
    box-shadow: 1px 1px 2px 1.5px rgba(0, 0, 0, 0.3); 
    background-color: #ffffef;
    flex-shrink: 0; /* Prevent shrinking */
    flex-basis: 0;
    flex-direction: column; /* Change flex direction to row */
    position: relative; /* Make sure it's positioned relative to allow z-index to work */
    z-index: 100;
}

.experience-container {
    display: flex;
    min-width: 33%;
    padding-bottom: 0px !important;
    flex-direction: row; /* Change flex direction to row */
    position: relative; /* Make sure it's positioned relative to allow z-index to work */
    z-index: 100;
}

.experience-image {
    max-width: 110px;
    margin-right: 10px; /* Add spacing between the image and text */
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
    user-select: none;
    flex: 1;
}

.experience-text {
    flex: 1; /* Allow the text div to grow and take available space */
    padding-top: 10px;
    padding-bottom: 0px !important;
}

h1 {
    font-family: "IM Fell DW Pica", serif !important;
    text-align: center;
    color: black; 
    width: 100%; 
    font-size: 24px;
}

h2 {
    font-family: "Zilla Slab", serif !important;
    font-size: 24px;
    color: black;
    padding-left: 20px;
}

h3 {
    font-family: "Zilla Slab", serif !important;
    font-size: 16px;
    color: black;
}

.button-title {
    font-family: "Zilla Slab", serif !important;
    padding: 6px 8px !important;
    font-size: 16px;
    color: black;
}

.button-title:hover {
    color: #ffffef;
}

p {
    font-family: "Archivo", "Helvetica", sans-serif !important;
    padding-top: 15px;
    padding-bottom: 0px;
}

@media (min-width: 1920px) {
    p {
      font-size: 18px; /* Increase font size for screens larger than 768px */
    }
    h3 {
        font-size: 20px;
    }
}

@media (min-height: 1080px) {
    p {
      font-size: 18px; /* Increase font size for screens larger than 768px */
    }
    h3 {
        font-size: 20px;
    }
}

@media (min-width: 3840px) {
    p {
      font-size: 20px; /* Increase font size for screens larger than 768px */
    }
    h3 {
        font-size: 22px;
    }
}

@media (min-height: 2160px) {
    p {
      font-size: 20px; /* Increase font size for screens larger than 768px */
    }
    h3 {
        font-size: 22px;
    }
}

.text-container {
    font-family: "Helvetica", sans-serif !important;
    padding-top: 15px;
    padding-bottom: 0px;
}

/* Disable selection */
.disable-select {
    user-select: none;
}

.compact-title {
    align-items: end !important;
    vertical-align: text-bottom !important;
}

.content-grid {
    display: grid; 
    grid-template-columns: repeat(auto-fill, minmax(333px, 1fr));
    gap: 15px;
}

@media (max-width: 1200px) {
    .content-grid {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}

.content-card {
    border-radius: 15px;
    margin: 15px;
    min-height: 133px;
    height: 12vh;
    box-shadow: 1px 1px 2px 1.5px rgba(0, 0, 0, 0.3);
    background-color: #ffffef;
    overflow: hidden;
    user-select: none;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 100;
    cursor: pointer;
}

.content-contact {
    border-radius: 15px;
    margin: 15px;
    min-height: 133px;
    min-width: 133px;
    width: 10vh;
    box-shadow: 1px 1px 2px 1.5px rgba(0, 0, 0, 0.3);
    background-color: #ffffef;
    user-select: none;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    z-index: 100;
    cursor: pointer;
}

.content-contact:hover img {
    filter: invert(1);
}

.content-contact-button {
    background-color: #ffffef;
    color: black;
    box-shadow: 0.5px 0.5px 0.5px 1px rgba(0, 0, 0, 0.1); 
    display: block !important; /* Display as block-level elements to take full width */
    text-align: center; /* Center-align text within the button */
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-weight: bold !important; /* Set the font weight to bold */
    transition: opacity 1s ease !important; /* Add a transition for the opacity property */
}
  
.content-contact-button:hover {
    background-color: rgb(4, 4, 146) !important;
    color: #ffffef !important;
}

@media only screen and (max-width: 400px) and (orientation: portrait) {
    .content-card {
        height: 30vw;
    }
    .thumbnail-container {
        flex: 1.3 !important;
    }
}

@media only screen and (max-height: 800px) and (orientation: portrait) {
    .content-card {
        height: 20vh;
    }
    .thumbnail-container {
        flex: 1.5 !important;
    }
}

@media only screen and (min-width: 401px) and (max-width: 600px) and (orientation: portrait) {
    .content-card {
        height: 30vw;
    }
    .thumbnail-container {
        flex: 1.15 !important;
    }
}

@media only screen and (max-height: 400px) and (orientation: landscape) {
    .content-card {
        height: 40vh;
    }
    .thumbnail-container {
        flex: 1.4 !important;
    }
}

@media only screen and (min-height: 401px) and (max-height: 600px) and (orientation: landscape) {
    .content-card {
        height: 30vh;
    }
    .thumbnail-container {
        flex: 1.3 !important;
    }
}

@media only screen and (min-height: 601px) and (max-height: 800px) and (orientation: landscape) {
    .content-card {
        height: 20vh;
    }
    .thumbnail-container {
        flex: 1.88 !important;
    }
}

.project-thumbnail {
    z-index: 200;
    position: relative;
    max-width: 100%;
    display: flex;
    flex: 1;
    object-fit: cover;
    object-position: center;
}

.thumbnail-container {
    flex: 1.5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
}

.media-thumbnail {
    z-index: 200;
    position: relative;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
    height: auto;
    max-width: 100%;
    max-height: 350px;
    user-select: none;
    object-fit: cover;
    object-position: top;
}

.project-text {
    flex: 2
}

.project-title {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 15px;
}

.project-description-short {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px !important;
    padding-bottom: 10px;
    font-size: 14px;
}

h4 {
    font-family: "Archivo", "Helvetica", sans-serif !important;
}

h5 {
    font-family: "Archivo", "Helvetica", sans-serif !important;
}

h6 {
    font-family: "Archivo", "Helvetica", sans-serif !important;
}

.modal-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80%;
    width: 75%;
    background-color: #ffffef;
    box-shadow: 1px 1px 2px 1.5px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding: 4;
    overflow-y: auto;
    overflow-x: hidden;
}

.project-media {
    min-height: 166px;
    min-width: 250px;
    height: 10vh;
    width: 2vw;
    object-fit: cover;
    object-position:top;
    border-radius: 15px;
    border-color: black;
    border-width: 1px;
    overflow-y: hidden;
}

.project-scroller {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    user-select: none;
}

.project-container {
    padding: 10px;
    max-height: 220px;
}

ul {
    margin-top: 8px;
    list-style-type: disc; /* or your preferred list-style-type */
  }
  
  li {
    
    margin-bottom: 8px; /* Adjust the value to increase or decrease the space between bullet points */
  }